.custom-shadow{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
    transition: all .66s;
}
.border-b-s-r-10{
    border-bottom-left-radius: 10px;
    transition: all .66s;
}
.border-b-e-r-10{
    border-bottom-right-radius: 10px;
    transition: all .66s;
}

#cosafacciamo .testohover {
    font-size: 20px;
    color: white;
    display: none;
    transition: all .66s .66s;
}

#cosafacciamo .custom-shadow:hover .testohover {
    display: block;
    position: relative;
    z-index: 10;
    font-weight: 600;
    transition: all .66s .120s;
}
#cosafacciamo .custom-shadow:hover img {
    display: none;
    transition: all .66s;
}
#cosafacciamo .custom-shadow:hover p {
    display: none;
    transition: all .66s;
}
#cosafacciamo .custom-shadow:hover {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: wrap;
    transition: all .66s;
}
#cosafacciamo .custom-shadow:hover .border-card {
    height: 100%;
    border-radius: 10px;
    transition: all .66s;
}