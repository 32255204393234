.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: relative;
  border: 6px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.loading-container {
  width: 200px;
  height: 200px;
  background-color: #ccc;
  position: relative; /* Aggiungi questa proprietà per posizionare correttamente il logo */

  /* Imposta l'animazione di riempimento */
  animation: fillLogo 3s ease-in-out infinite;
}

.loading-logo {
  width: 100%;
  height: 100%;
  background-image: url('./assets/logo.png'); /* Imposta l'immagine del logo colorato */
  background-size: 0% 100%;
  position: absolute; /* Posiziona il logo sopra il contenitore di caricamento */
  top: 0;
  left: 0;
}

@keyframes fillLogo {
  0% {
    background-size: 0% 100%;
  }
  100% {
    background-size: 100% 100%;
  }
}

