.bg-purple-custom{
    background: linear-gradient(90deg, rgba(94, 35, 103, 0.3) 0%, rgba(94, 35, 103, 0.3) 50%, rgba(94, 35, 103, 1) 50%);
}

@media(max-width: 1024px){
    .bg-purple-custom{
        background: rgba(94, 35, 103, 1);
    }
    .bg-purple-custom *{
        color: white!important;
    }
}
