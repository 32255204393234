
.slideshow .image img{
    height: calc(100vh - 128px);
    object-fit: cover;
    border: 0;
    max-width: 100%;
    min-height: 1px;
}
.slideshow .slide__caption{
    padding: 0px;
}

.views-field.views-field-nothing, .views-field.views-field-nothing span {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

@media(min-width: 768px) {

    .slick-active .sfondo-viola {
        background: rgba(94, 35, 103, 0.7);
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100vh;
        clip-path: polygon(0 0, 100% 0%, 100% 75%, 0 45%);
        animation-name: sfondoviola;
        animation-duration: 1s;
    }

    .slick-active .sfondo-giallo {
        background: rgba(252, 183, 36, 0.7);
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 100%;
        height: 100vh;
        clip-path: polygon(0 56%, 100% 86%, 100% 100%, 0 100%);
        animation-name: sfondogiallo;
        animation-duration: 1s;
    }


    @keyframes sfondoviola {
        from {
            clip-path: polygon(0 0, 100% 0%, 100% 0%, 0 0%);
        }

        to {
            clip-path: polygon(0 0, 100% 0%, 100% 75%, 0 45%);
        }
    }


    @keyframes sfondogiallo {
        from {
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
        }

        to {
            clip-path: polygon(0 56%, 100% 86%, 100% 100%, 0 100%);
        }
    }
    .slideshow-title {
        position: absolute;
        z-index: 9999;
        color: white;
        left: 50%;
        transform: translateX(-50%);
        top: 20px;
        font-size: 80px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .testo-descrittivo {
        position: absolute;
        z-index: 9999;
        color: white;
        left: 50%;
        transform: translateX(-50%);
        top: 260px;
        font-size: 30px;
        font-weight: bold;
    }
    .image-ratio {
        position: absolute;
        z-index: 9999;
        color: white;
        left: 50%;
        transform: translateX(-50%);
        top: 60px;
        font-size: 30px;
        font-weight: bold;
    }
}

.slideshow .slick-prev,
.slideshow .slick-next{
    display: none!important;
}




.slideshow-title a{
    color: white!important;
}


@media(max-width: 768px){
    .slideshow .image img{
        height: calc(50vh - 128px);
    }
    .slick-active .sfondo-viola {
        background: rgba(94, 35, 103, 0.7);
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;
        /*height: 50vh;*/
        /*clip-path: polygon(0 0, 100% 0%, 100% 65%, 0 35%)!important;*/
        /*animation-name: sfondoviola;*/
        /*animation-duration: 1s;*/
        z-index: 10;
        height: 100%;

    }

    .image-ratio,
    .testo-descrittivo{
        z-index: 20;
    }

    .image-ratio{
        width: 300px;
        margin: 0 auto;
    }

    .content{
        position: absolute;
        z-index: 20;
    }
    .image{
        position: relative;
        z-index: 10;
    }



    .slick-active .sfondo-giallo {
        display: none;
        background: rgba(252, 183, 36, 0.7);
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 100%;
        height: 50vh;
        clip-path: polygon(0 56%, 100% 86%, 100% 100%, 0 100%);
        animation-name: sfondogiallo;
        animation-duration: 1s;
        z-index: 10;
    }


    @keyframes sfondoviola {
        from {
            clip-path: polygon(0 0, 100% 0%, 100% 0%, 0 0%);
        }

        to {
            clip-path: polygon(0 0, 100% 0%, 100% 65%, 0 35%)!important;
        }
    }


    @keyframes sfondogiallo {
        from {
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
        }

        to {
            clip-path: polygon(0 56%, 100% 86%, 100% 100%, 0 100%);
        }
    }
    .slideshow-title {
        position: absolute;
        z-index: 9999;
        color: white;
        left: 40px;
        top: 20px;
        font-size: 50px;
        text-transform: uppercase;
        font-weight: bold;
    }

}
